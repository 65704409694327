
<template>
  <el-dialog v-model="dialogFormVisible" :title="title" width="500px" @close="close">
    <el-form ref="formRef" label-width="80px" :model="form" :rules="rules">
      <el-form-item label="文章标题" prop="title">
        <el-input v-model="form.title" />
      </el-form-item>
      <el-form-item label="文章封面" prop="coverUrl">
        <el-upload class="upload-demo" action="#" list-type="picture-card" :limit="1" :file-list="form.imgList" :http-request="upLogo" :on-error="handleError" :on-exceed="handleExceed1" :on-preview="handlePreview" :on-remove="handleRemove1" accept=".png,.jpeg,.jpg">
          <el-icon class="el-icon--upload">
            <upload-filled />
          </el-icon>
          <div class="el-upload__text">
            <em>点击上传图片</em>
          </div>
        </el-upload>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>

    <el-dialog v-model="dialogVisible" append-to-body title="查看大图">
      <div>
        <el-image :src="dialogImageUrl" />
      </div>
    </el-dialog>

  </el-dialog>
</template>

<script>
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
} from 'vue'
import { newsSaveOrUpdate, enterpriseEditMaterial } from '@/api/content'
import { upLoadImg, upLoadAttchments } from '@/api/active'

export default defineComponent({
  name: 'tagEdit',
  emits: ['fetch-data'],

  props: {
    enterpriseCode: {
      type: String,
      default: '',
    },
    tagList: {
      type: [Array, String],
      default: [],
    },
  },
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance()

    const validateCover = (rule, value, callback) => {
      if (state.form.coverUrl === '') {
        callback(new Error('请上传图片'))
      } else {
        callback()
      }
    }

    const state = reactive({
      formRef: null,
      treeRef: null,
      checkMenu: [],
      dialogVisible: false,
      dialogImageUrl: '',
      form: {
        type: `OFFICIAL_WEBSITE`,
        coverUrl: '',
        imgList: [],
      },
      rules: {
        title: [{ required: true, trigger: 'blur', message: '请输入标题' }],
        coverUrl: [
          { required: true, trigger: 'change', validator: validateCover },
        ],
      },
      title: '',
      dialogFormVisible: false,
      list: [],
      tagOption: [],
    })

    const showEdit = async (row) => {
      if (!row) {
        state.title = '添加'
      } else {
        state.title = '编辑'
        row.imgList = [{ url: row.coverUrl, name: row.coverUrl }]
        state.form = Object.assign({}, row)
      }
      let customInfo = localStorage.getItem('customInfo')
      let tenantKey = ''
      if (customInfo != undefined && customInfo != 'undefined') {
        customInfo = JSON.parse(customInfo)
        if (!!customInfo.tenantKey) {
          tenantKey = customInfo.tenantKey
        } else {
          tenantKey = ''
        }
      }
      if (tenantKey == 'GDC') {
        state.form.type = 'GDC_OFFICIAL_WEBSITE'
      } else {
        state.form.type = `OFFICIAL_WEBSITE`
      }

      state.dialogFormVisible = true
    }
    const close = () => {
      state['formRef'].resetFields()
      state.form = {
        btnRolesCheckedList: [],
        type: `OFFICIAL_WEBSITE`,
        imgList: [],
      }
      state.dialogFormVisible = false
    }

    const save = () => {
      state['formRef'].validate(async (valid) => {
        if (valid) {
          let msg = ''
          let data = await newsSaveOrUpdate(state.form)
          msg = data.msg
          proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
          emit('fetch-data')
          close()
        }
      })
    }

    // 上传海报
    const upLogo = async (e) => {
      let upData = new FormData()
      upData.append('file', e.file)
      const { data } = await upLoadAttchments(upData)
      console.log(data)
      state.form.coverUrl = data.fileUrl
    }

    const handleError = (err, file) => {
      proxy.$baseMessage(
        `文件[${file.raw.name}]上传失败,文件大小为${_.round(
          file.raw.size / 1024,
          0
        )}KB`,
        'error',
        'vab-hey-message-error'
      )
    }

    const handleExceed1 = (files) => {
      proxy.$baseMessage(
        `当前限制选择1个文件，本次选择了
             ${files.length}
             个文件`,
        'error',
        'vab-hey-message-error'
      )
    }

    const handleRemove1 = (file, fileList) => {
      state.form.pageImage = ''
    }

    const handlePreview = (file) => {
      state.dialogImageUrl = file.url
      console.log(file.url)
      state.dialogVisible = true
    }

    return {
      ...toRefs(state),
      showEdit,
      close,
      save,
      upLogo,
      handleError,
      handleExceed1,
      handleRemove1,
      handlePreview,
    }
  },
})
</script>

<style lang="scss" scoped>
.vab-tree-border {
  height: 200px;
  padding: $base-padding;
  overflow-y: auto;
  border: 1px solid #dcdfe6;
  border-radius: $base-border-radius;
}
</style>
